$imgp: "../images/";

@-webkit-keyframes totop {
	0% {
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
	50% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
}

@keyframes totop {
	0% {
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
	50% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
}
@mixin back($i, $r, $v) {
	background-image: url($imgp + $i + ".png");
	background-repeat: no-repeat;
	background-size: $r $v;
}
ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}

html,
body {
	margin: 0px;
	padding: 0px;
	font-family: "微软雅黑", "Open Sans", sans-serif;
}
/*-- header --*/
/*------------------ Slider Part starts Here----------*/
#slider3,
#slider4 {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	margin: 0 auto;
}
.rslides_tabs {
	list-style: none;
	padding: 0;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.3), inset 0 0 5px rgba(0, 0, 0, 1);
	-moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3), inset 0 0 5px rgba(0, 0, 0, 1);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3), inset 0 0 5px rgba(0, 0, 0, 1);
	font-size: 18px;
	list-style: none;
	margin: 0 auto 50px;
	max-width: 540px;
	padding: 10px 0;
	text-align: center;
	width: 100%;
	li {
		display: inline;
		float: none;
		margin-right: 1px;
		&:first-child {
			margin-left: 0;
		}
	}
	a {
		width: auto;
		line-height: 20px;
		padding: 9px 20px;
		height: auto;
		background: transparent;
		display: inline;
	}
	.rslides_here a {
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
		font-weight: bold;
	}
}

.events {
	list-style: none;
}
.callbacks_container {
	position: relative;
	float: left;
	width: 100%;
}
.callbacks {
	position: relative;
	list-style: none;
	overflow: hidden;
	width: 100%;
	padding: 0;
	margin: 0;
	li {
		position: absolute;
		left: 0;
		top: 0;
	}
	img {
		position: relative;
		z-index: 1;
		height: auto;
		border: 0;
	}
	.caption {
		display: block;
		position: absolute;
		z-index: 2;
		font-size: 20px;
		text-shadow: none;
		color: #fff;
		left: 0;
		right: 0;
		padding: 10px 20px;
		margin: 0;
		max-width: none;
		top: 10%;
		text-align: center;
	}
}

.callbacks_nav {
	position: absolute;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	top: 72%;
	left: 14%;
	opacity: 0.7;
	z-index: 3;
	text-indent: -9999px;
	overflow: hidden;
	text-decoration: none;
	height: 50px;
	width: 31px;
	margin-top: -65px;
	&:active {
		opacity: 1;
	}
	&.next {
		left: auto;
		background-position: right top;
		right: 14%;
	}
}
#slider3-pager,
#slider4-pager {
	a {
		display: inline-block;
	}
}
#slider3-pager span,
#slider4-pager span {
	float: left;
}
#slider3-pager span,
#slider4-pager span {
	width: 100px;
	height: 15px;
	background: #fff;
	display: inline-block;
	border-radius: 30em;
	opacity: 0.6;
}
#slider3-pager .rslides_here a,
#slider4-pager .rslides_here a {
	background: #fff;
	border-radius: 30em;
	opacity: 1;
}
#slider3-pager a,
#slider4-pager a {
	padding: 0;
}
#slider3-pager li,
#slider4-pager li {
	display: inline-block;
}
.rslides {
	position: relative;
	list-style: none;
	overflow: hidden;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		-webkit-backface-visibility: hidden;
		position: absolute;
		display: none;
		left: 0;
		top: 0;
	}
	li {
		position: relative;
		display: block;
		float: left;
	}
	img {
		height: auto;
		border: 0;
	}
}
.callbacks_tabs {
	list-style: none;
	position: absolute;
	top: 120%;
	z-index: 999;
	left: 41%;
	padding: 0;
	margin: 0;
}
.slider-top {
	text-align: center;
	padding: 10em 0;
	h1 {
		font-weight: 700;
		font-size: 48px;
		color: #010101;
	}
	p {
		font-weight: 400;
		font-size: 20px;
		padding: 1em 7em;
		color: #010101;
	}
	ul.social-slide {
		display: -webkit-inline-box;
		display: inline-flex;
		margin: 0px;
		padding: 0px;
	}
}
ul.social-slide li i {
	width: 70px;
	height: 74px;
	display: inline-block;
	margin: 0px 15px;
}
ul.social-slide li i.win {
	background-position: -6px 0px;
}
ul.social-slide li i.android {
	background-position: -110px 0px;
}
ul.social-slide li i.mac {
	background-position: -215px 0px;
}
.callbacks_tabs li {
	display: inline-block;
	margin: 0 0.5em;
}
@media screen and (max-width: 600px) {
	.callbacks_nav {
		top: 47%;
	}
}
/*----*/
.callbacks_tabs a {
	visibility: hidden;
}
.callbacks_tabs a:after {
	content: "\f111";
	font-size: 0;
	font-family: FontAwesome;
	visibility: visible;
	display: block;
	height: 17px;
	width: 17px;
	display: inline-block;
	background: #f2f2f2;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-o-border-radius: 30px;
	-ms-border-radius: 30px;
}
.callbacks_here a:after {
	background: #bababa;
}
/*------------------ Slider Part ends Here----------*/
/*-- header --*/
.head-logo {
	float: left;
	margin: 20px 0px;
	a {
		float: left;
	}
}
.top-nav {
	float: left;
}
.top-nav ul {
	padding: 0px;
	margin: 2.3em 0px 2em 15em;
}
.top-nav ul li {
	display: inline-block;
	margin-left: 3.2em;
}
.top-nav ul li a {
	text-decoration: none;
	color: #2e3b4c;
	font-size: 15px;
	font-weight: 600;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
.top-nav ul li a:hover {
	text-decoration: none;
	color: #ffca26;
	border-bottom: 3px solid #ffca26;
	padding: 8px 0px;
}
.top-nav span {
	display: none;
}
/*-- //header --*/
/*-- banner --*/
.banner {
	animation: bgswiper 60s linear infinite;
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	width: 100%;
	min-height: 791px;
	background-color: #ffc932;
}
@keyframes bgswiper {
	0% {
		background-image: url(../images/bg1.jpg);
	}
	20% {
		background-image: url(../images/bg2.jpg);
	}
	40% {
		background-image: url(../images/bg3.jpg);
	}
	60% {
		background-image: url(../images/bg4.jpg);
	}
	80% {
		background-image: url(../images/bg5.jpg);
	}
	100% {
		background-image: url(../images/bg1.jpg);
	}
}
@-webkit-keyframes bgswiper {
	0% {
		background-image: url(../images/bg1.jpg);
	}
	20% {
		background-image: url(../images/bg2.jpg);
	}
	40% {
		background-image: url(../images/bg3.jpg);
	}
	60% {
		background-image: url(../images/bg4.jpg);
	}
	80% {
		background-image: url(../images/bg5.jpg);
	}
	100% {
		background-image: url(../images/bg1.jpg);
	}
}

.banner-info {
	text-align: center;
	width: 100%;
	margin-top: 20em;
}
.banner-info h1 {
	font-size: 40px;
	color: #ffcc33;
	font-weight: 700;
	margin: 0px;
}
.banner-info p {
	color: #fff;
	font-size: 20px;
	margin: 1em 0px 8em;
}
.banner-info a {
	font-size: 25px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #fff;
	border-bottom: 3px solid #ffca26;
	padding: 8px 0px 15px;
}
.banner-info a:hover {
	color: #ffca26;
	text-decoration: none;
	border-bottom: 3px solid #ffffff;
}
/*-- //banner --*/
/*-- banner-bottom --*/
.banner-bottom {
	background: #ffca26;
	padding: 6em 0px;
}
.banner-bottom h3 {
	background: url(../images/e.png) no-repeat 11.8em 0px;
	height: 49px;
	margin: 0em -0.2em 1em;
	color: #fff;
	text-align: center;
	font-size: 36px;
}
.banner-bottom p {
	color: #fff;
	text-align: center;
	width: 100%;
	margin: 0px;
	font-size: 20px;
}
.banner-bottom span {
	display: block;
}
/*-- //banner-bottom --*/
/*-- what-we-do --*/
.what-we-do h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.what-we-do h3 span {
	color: #ffcc33;
	margin-left: 0.15em;
	font-weight: 600;
}
.what-we-do p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 18px;
	text-align: center;
	line-height: 1.9em;
}
.what-we-do label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
.what-we-do-articles {
	width: 50%;
	float: right;
	margin: 9em 0px 5em;
}
.brand-identity {
	margin-bottom: 1.5em;
}
.brand-identity-left-text {
	float: left;
	width: 67%;
}
.brand-identity-left-text h2 {
	color: #999999;
	margin: 10px 0px;
	font-size: 20px;
	text-align: right;
	font-weight: 600;
}
.brand-identity-left-text p {
	text-align: right;
	font-size: 15px;
	color: #555;
	margin: 0 0 0 6em;
	font-family: "Open Sans", sans-serif;
	font-style: normal;
}
.brand-identity-left-fig {
	float: left;
	width: 25%;
	margin-left: 3em;
}
.brand-identity-left-fig img {
	width: 100%;
}
.brand-identity-left-text1 {
	float: left;
	width: 67%;
}
.brand-identity-left-text1 h2 {
	color: #999999;
	margin: 10px 0px;
	font-size: 20px;
	font-weight: 600;
}
.brand-identity-left-text1 p {
	font-size: 15px;
	color: #555;
	text-align: left;
	width: 78%;
	margin: 0;
	font-family: "Open Sans", sans-serif;
	font-style: normal;
}
.brand-identity-left-fig1 {
	float: left;
	width: 25%;
	margin-right: 2em;
}
.brand-identity-left-fig1 img {
	width: 100%;
}
/*-- //what-we-do --*/
/*-- want-to-know --*/
.want-to-know {
	margin-bottom: 5em;
}
.want-to-know h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.want-to-know h3 span {
	color: #ffcc33;
	margin-left: 0.15em;
	font-weight: 600;
}
.want-to-know p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 18px;
	font-weight: 300;
	text-align: center;
}
.personaljj {
	line-height: 1.9em;
	color: #777;
	width: 80%;
	float: left;
	margin-left: 3em;
}
.jianjie {
	width: 50%;
	float: left;
	margin: 7em 0 7em;
	h4 {
		color: #777;
	}
	ul {
		margin-left: 3em;
		li {
			margin: 3em 0;
		}
	}
}
@media screen and (max-width: 768px) {
	.jianjie {
		margin: 1em 0 1em;
		width: 100%;
		ul {
			margin: 0;
			li {
				margin: 1.5em 0;
				padding: 0 1em;
			}
		}
	}
	.xiaoping {
		color: #ffc100;
	}
}
.want-to-know label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
/*-- want-to-know --*/
/*-- professional --*/
.professional {
	background: #f3f3f3;
	padding-bottom: 6em;
}
.skill-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.professional-left {
	width: 45%;
	float: left;
	position: relative;
	img {
		width: 100%;
		position: relative;
	}
	ul {
		position: absolute;
		top: 86.5%;
		left: 18%;
		margin: 0px;
		padding: 0px;
		li {
			display: inline-block;
			a.facebook {
				display: block;
				width: 68px;
				height: 68px;
				transition: all 0.5s linear;
				-moz-transition: all 0.5s linear;
				-ms-transition: all 0.5s linear;
				-o-transition: all 0.5s linear;
				-webkit-transition: all 0.5s linear;
			}
			a.twitter {
				display: block;
				width: 68px;
				height: 68px;
				transition: all 0.5s linear;
				-moz-transition: all 0.5s linear;
				-ms-transition: all 0.5s linear;
				-o-transition: all 0.5s linear;
				-webkit-transition: all 0.5s linear;
			}
			a.in {
				display: block;
				width: 68px;
				height: 68px;
				transition: all 0.5s linear;
				-moz-transition: all 0.5s linear;
				-ms-transition: all 0.5s linear;
				-o-transition: all 0.5s linear;
				-webkit-transition: all 0.5s linear;
			}
			a.facebook:hover {
				display: block;
			}
			a.twitter:hover {
				display: block;
			}
			a.in:hover {
				display: block;
			}
		}
		h3 {
			color: #333333;
			margin: 0px;
			text-align: right;
			position: absolute;
			top: 101%;
			left: 6%;
			font-size: 31px;
			margin: 2em 0px;
			label {
				font-weight: 700;
				font-size: 32px;
			}
			span {
				display: block;
			}
		}
	}
}
.fig img {
	width: 100%;
}
.professional-right {
	float: left;
	width: 45%;
	margin-left: 6em;
}
.professional-right h4 {
	font-weight: 700;
	color: #000;
	margin: 3em 0px 2em;
}
.professional-right p {
	font-size: 14px;
	color: #bdbdbd;
	margin: 0px 0px 4em;
	font-style: normal;
	// font-weight:600;
}
.professional-grids {
	border-bottom: 1px solid #555;
	border-top: 1px solid #555;
}
.professional-grids-left1 {
	border-right: 1px solid #555;
	padding: 8em 0px;
}
.professional-grids-left2 {
	border-left: 1px solid #555;
	padding: 8em 0px;
}
.professional-grids-finger {
	width: 75px;
	height: 108px;
	display: block;
	border-right: 1px solid #555;
	padding: 8em 0px;
}
.professional-grids-finger h3 {
	color: #000;
	text-align: center;
	margin: 0.5em 0px;
	font-weight: 700;
}
.professional-grids-finger p {
	color: #999999;
	margin: 0px;
	font-size: 17px;
	font-family: "Lato", sans-serif;
	font-style: italic;
	text-align: center;
}
.professional-grids-msg {
	width: 75px;
	height: 108px;
	display: block;
	border-right: 1px solid #555;
	padding: 8em 0px;
}
.professional-grids-msg h3 {
	color: #000;
	text-align: center;
	margin: 0.5em 0px;
	font-weight: 700;
}
.professional-grids-msg p {
	color: #999999;
	margin: 0px;
	font-size: 17px;
	font-family: "Lato", sans-serif;
	font-style: italic;
	text-align: center;
}
.professional-grids-msgtype {
	width: 75px;
	height: 108px;
	display: block;
	padding: 8em 0px;
}
.professional-grids-msgtype h3 {
	color: #000;
	text-align: center;
	margin: 0.5em 0px;
	font-weight: 700;
}
.professional-grids-msgtype p {
	color: #999999;
	margin: 0px;
	font-size: 17px;
	font-family: "Lato", sans-serif;
	font-style: italic;
	text-align: center;
}
.professional-grids-men {
	width: 75px;
	height: 108px;
	display: block;
	border-right: 1px solid #555;
	padding: 8em 0px;
}
.professional-grids-men h3 {
	color: #000;
	text-align: center;
	margin: 0.5em 0px;
	font-weight: 700;
}
.professional-grids-men p {
	color: #999999;
	margin: 0px;
	font-size: 17px;
	font-family: "Lato", sans-serif;
	font-style: italic;
	text-align: center;
}
.professional-grids {
	background: #f3f3f3;
}
.skills {
	width: 85%;
	margin: 29px 0;
	height: 20px;
	background: #999;
}
.bar p {
	color: #999999;
	font-size: 18px;
	margin: 0px;
}
.skill1 {
	width: 0;
	height: 100%;
	display: block;
	background-color: #ffca26;
}
.skill2 {
	height: 100%;
	display: block;
	background-color: #ffca26;
}
.skill3 {
	height: 100%;
	display: block;
	background-color: #ffca26;
}
.skill4 {
	height: 100%;
	display: block;
	background-color: #ffca26;
}
.skill5 {
	height: 100%;
	display: block;
	background-color: #ffca26;
}
/*-- //professional --*/
/*-- great-team --*/
.great-team {
	margin-top: 5em;
}
.great-team h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.great-team h3 span {
	color: #ffcc33;
	font-weight: 600;
}
.great-team p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 21px;
	text-align: center;
}
.great-team label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
.maria {
	margin-top: 5em;
}
.maria h2 {
	color: #000;
	margin: 10px 40px 10px 0px;
	text-align: center;
}
.maria p {
	color: #ababab;
	font-size: 16px;
	margin: 10px 2em 10px 0px;
	font-family: "Open Sans", sans-serif;
	font-style: normal;
}
.maria ul {
	margin: 0px 0px 0px 5.5em;
	padding: 0px;
}
.maria ul li {
	display: inline-block;
	margin: 0px 3px;
}
.maria ul li a.facebook1 {
	display: block;
	width: 45px;
	height: 45px;
	transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
}
.maria ul li a.twitter1 {
	display: block;
	width: 45px;
	height: 45px;
	transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
}
.maria ul li a.in1 {
	display: block;
	width: 45px;
	height: 45px;
	transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
}
.maria ul li a.facebook1:hover {
	display: block;
}
.maria ul li a.twitter1:hover {
	display: block;
}
.maria ul li a.in1:hover {
	display: block;
}
.cap p {
	margin: 0px;
	color: #fff;
	font-size: 14px;
}
.cap span {
	display: block;
}
.cap {
	display: none;
}
.cap {
	position: absolute;
	top: 55%;
	left: 3%;
	background: rgba(0, 0, 0, 0.78);
	padding: 3em 1.5em;
	z-index: 999;
}
.maria {
	position: relative;
}
.maria:hover div.cap {
	display: block;
}
/*-- great-team --*/
/*-- welcome --*/
.welcome {
	margin-top: 20%;
	margin-bottom: 5%;
}
.welcome h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.welcome h3 span {
	color: #ffcc33;
	font-weight: 600;
}
.welcome p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 22px;
	text-align: center;
	font-family: "Lato", sans-serif;
	font-style: italic;
}
.welcome label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
/*-- //welcome --*/
/*-- internet-comm --*/
.internet-comm {
	background: #f3f3f3;
	padding: 10em 0px 12em;
}
.internet-comm-left {
	float: left;
	width: 31%;
}
.tv {
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	padding: 2em 2em;
	background: #fff;
	box-shadow: 0 2px #555;
	margin-bottom: 1em;
}
.tv:hover {
	background: #ffca26;
}
.figure {
	background: url(../images/sp.png) no-repeat 0px 0px;
	width: 20%;
	height: 46px;
	display: block;
	float: left;
}
.tv:hover div.figure {
	background: url(../images/sp.png) no-repeat 0px -46px;
	display: block;
}
.figure1 {
	background: url(../images/sp.png) no-repeat -59px 0px;
	width: 20%;
	height: 46px;
	display: block;
	float: left;
}
.tv:hover div.figure1 {
	background: url(../images/sp.png) no-repeat -59px -46px;
	display: block;
}
.figure2 {
	background: url(../images/sp.png) no-repeat -114px 0px;
	width: 20%;
	height: 46px;
	display: block;
	float: left;
}
.tv:hover div.figure2 {
	background: url(../images/sp.png) no-repeat -114px -46px;
	display: block;
}
.figure3 {
	background: url(../images/sp.png) no-repeat -162px 0px;
	width: 20%;
	height: 46px;
	display: block;
	float: left;
}
.tv:hover div.figure3 {
	background: url(../images/sp.png) no-repeat -162px -46px;
	display: block;
}
.figure4 {
	background: url(../images/sp.png) no-repeat -210px 0px;
	width: 20%;
	height: 46px;
	display: block;
	float: left;
}
.tv:hover div.figure4 {
	background: url(../images/sp.png) no-repeat -210px -46px;
	display: block;
}
.text {
	float: right;
	width: 75%;
	margin-top: 0.5em;
}
.text h5 {
	color: #707070;
	margin: 0px;
}
.text p {
	margin: 0px;
	color: #a7a7a7;
	font-size: 15px;
}
.tv:hover div.text h5 {
	color: #fff;
}
.tv:hover div.text p {
	color: #fff;
}
.internet-comm-right {
	float: left;
	width: 60%;
	padding-left: 9em;
}
.internet-comm-right h3 {
	color: #000;
	margin: 0px 0px 1em;
	font-weight: 700;
}
.internet-comm-right p {
	margin: 0px 0 8em;
	color: #999999;
	font-size: 15px;
	width: 80%;
}
/*-- //internet-comm --*/
/*-- our-work --*/
.our-work {
	margin-top: 3%;
	margin-bottom: 5%;
}
.our-work h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.our-work h3 span {
	color: #ffcc33;
	font-weight: 600;
	margin-left: 0.15em;
}
.our-work p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 18px;
	text-align: center;
}
.our-work label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
/*	Strip
/*-----------------------------------------------------------------------------------*/
.b-link-stripe {
	position: relative;
	display: inline-block;
	vertical-align: top;
	font-weight: 300;
	overflow: hidden;
}

.b-link-stripe .b-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	padding: 27% 0;
	color: #ffffff;
	overflow: hidden;
}
.b-link-stripe .b-wrapper:hover {
	background: rgba(245, 205, 82, 0.94);
	transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
}
/*-----------------------------------------------------------------------------------*/
/*	Animation effects
/*-----------------------------------------------------------------------------------*/
.b-animate-go {
	text-decoration: none;
}
.b-animate {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	visibility: hidden;
	font-size: 1.1em;
	font-weight: 700;
}
.b-animate img {
	margin-top: 23%;
	display: -webkit-inline-box;
}
/* lt-ie9 */
.b-animate-go:hover .b-animate {
	visibility: visible;
}
.b-from-left {
	position: relative;
	left: -100%;
}

.b-animate-go:hover .b-from-left {
	left: 0;
}
span.m_4 {
	font-size: 14px;
	font-weight: 400;
}
p.m_5 {
	margin: 2% auto 5%;
	width: 70%;
	color: #283a47;
	font-size: 1.1em;
	font-weight: 600;
	line-height: 1.5em;
	text-align: center;
}
ul#filters {
	padding: 0px;
}
#filters {
	margin: 3% 0;
	padding: 0;
	list-style: none;
	text-align: center;
	li {
		display: -webkit-inline-box;
		margin: 0 5px;
		span {
			display: block;
			padding: 5px 15px;
			text-decoration: none;
			color: #7a7a7a;
			cursor: pointer;
			font-size: 15px;
			font-weight: 500;
			border-radius: 3px;
			// border: solid 1px #aaa;
			transition: 0.2s all;
			box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1), -2px -2px 2px rgba(255, 255, 255, 0.9),
				inset 0 0 0 rgba(255, 255, 255, 0.9), inset 0 0 0 rgba(0, 0, 0, 0.4);
			&.active {
				display: block;
				padding: 5px 15px;
				text-decoration: none;
				background: #ffcb31;
				color: #fff;
				cursor: pointer;
				font-size: 15px;
				font-weight: 500;
				border: solid 1px #ffcb31;
				border-radius: 3px;
			}
			&:active {
				box-shadow: 0 0 0 rgba(0, 0, 0, 0.4), 0 0 0 rgba(255, 255, 255, 0.9),
					inset -2px -2px 2px rgba(255, 255, 255, 0.1), inset 3px 3px 7px rgba(0, 0, 0, 0.2);
			}
			&.filter:hover {
				background: #ffcb31;
				// border: 1px solid #ffcb31;
				color: #fff;
				border-radius: 3px;
				transition: 0.5s;
				-webkit-transition: 0.5s;
				-moz-transition: 0.5s;
				-o-transition: 0.5s;
				-ms-transition: 0.5s;
			}
		}
	}
}
#portfoliolist .portfolio {
	box-sizing: border-box;
	width: 16.66%;
	display: none;
	float: left;
	overflow: hidden;
}
div#portfoliolist {
	padding: 0px 0px;
}
.portfolio-wrapper {
	overflow: hidden;
	position: relative !important;
	cursor: pointer;
}
.portfolio img {
	max-width: 100%;
	/*--position: relative;--*/
	transition: all 300ms !important;
	-webkit-transition: all 300ms !important;
	-moz-transition: all 300ms !important;
}
.portfolio .label {
	position: absolute;
	width: 100%;
	height: 40px;
	bottom: -40px;
}
.portfolio .label-bg {
	background: #22b4b8;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.portfolio .label-text {
	color: #fff;
	position: relative;
	z-index: 500;
	padding: 5px 8px;
}

.portfolio .text-category {
	display: block;
	font-size: 9px;
	font-size: 12px;
	text-transform: uppercase;
}
/* Self Clearing Goodness */
.map-bottom {
	background: #f7f3f7;
	padding: 30px 0;
}
.container:after {
	content: "\0020";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
	content: "\0020";
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}
.row:after,
.clearfix:after {
	clear: both;
}
.row,
.clearfix {
	zoom: 1;
}

.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

/*----- effects-comman-css------*/
.contenthover {
	padding: 1em;
}
.contenthover h3 {
	color: #fff;
}
.contenthover p {
	color: #fff;
	font-size: 0.875em;
	line-height: 1.5em;
}
/*-----caption ------*/
.caption {
	text-align: left;
	margin-left: 9%;
}
.caption h3 {
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-weight: 700;
	font-size: 1.8em;
}
.caption p {
	text-align: left;
	font-size: 14px;
}
.caption small {
	display: block;
	text-align: left;
	font-size: 14px;
}
p.b-animate.b-from-left.b-delay03 {
	font-weight: 400;
	font-size: 13px;
	color: #4f4f4f;
	margin: 15px 0 0 0;
}
p.b-animate.b-from-left.b-delay03 small {
	display: block;
	font-size: 16px;
}
h5.b-animate.b-from-left.b-delay03 {
	font-size: 20px;
	font-weight: 600;
	color: #403d35;
	margin: 0;
}
ul.b-animate.b-from-left.b-delay03 {
	margin: 0px;
	padding: 0px;
}
ul.b-animate.b-from-left.b-delay03 li {
	display: inline-block;
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -o-inline-box;
	display: -ms-inline-box;
}
ul.b-animate.b-from-left.b-delay03 li span.link {
	background: url(../images/hovernew.png) no-repeat 0px 0px;
	width: 40px;
	height: 40px;
	display: block;
}
.zoom-icon {
	position: absolute;
	opacity: 0;
	filter: alpha(opacity=0);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
	display: block;
	left: 0;
	top: -152px;
	width: 290px;
	height: 219px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.grid_box .zoom-icon {
	top: -50px;
	width: 100%;
	height: 182px;
}
.grid_box a:hover .zoom-icon {
	top: 0;
	opacity: 1;
	filter: alpha(opacity=99);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=99)";
}
.entertain_box {
	margin-bottom: 3em;
}
/*-- //our-work --*/

/*-- pricing-table --*/
.pricing-table {
	margin-top: 10%;
	margin-bottom: 5%;
}
.pricing-table h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.pricing-table h3 span {
	color: #ffcc33;
	font-weight: 600;
}
.pricing-table p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 22px;
	text-align: center;
	font-family: "Lato", sans-serif;
	font-style: italic;
}
.pricing-table label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
.basic1:hover div.value h4 {
	background: #ffcc33;
}
.basic:hover div.value h4 {
	background: #ffcc33;
}
.value h4 {
	padding: 30px 0px;
	color: #fff;
	background: #000;
	margin: 0px;
	font-size: 25px;
}
.basic1:hover div.value p {
	border-bottom: 1px solid #ffcc33;
}
.basic:hover div.value p {
	border-bottom: 1px solid #ffcc33;
}
.value p {
	color: #666;
	margin: 0px;
	border-bottom: 1px solid #646464;
	font-size: 20px;
}
.value p span {
	font-size: 55px;
}
.value p label {
	font-size: 30px;
}
.basic:hover div.started a {
	color: #fff;
	background: #ffcc33;
	text-decoration: none;
}
.basic:hover {
	-webkit-transform: scale(1.09);
	transform: scale(1.09);
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	-ms-transition: 0.5s all;
	background: #fff;
	border: 1px solid #ffcc33;
}
.basic {
	border: 1px solid #000;
	width: 25%;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	padding: 0 !important;
	text-align: center;
	float: left;
	margin-left: 2px;
}
.basic ul {
	margin: 0px;
	padding: 0px;
}
.basic ul li {
	list-style-type: none;
	color: #838383;
	margin: 15px 0 18px;
}
.started {
	margin-top: 2.5em;
}
.started a {
	color: #fff;
	padding: 17px 7em;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	background: #333333;
	font-size: 11px;
}
.basic1:hover div.started a {
	color: #fff;
	background: #ffcc33;
	text-decoration: none;
}
.basic1:hover {
	-webkit-transform: scale(1.09);
	transform: scale(1.09);
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	-ms-transition: 0.5s all;
	background: #fff;
	border: 1px solid #ffcc33;
}
.para p {
	color: #838383;
	margin: 2em 0px 1em;
	font-size: 13px;
	font-weight: 300;
}
.basic1 {
	border: 1px solid #000;
	width: 25%;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	padding: 0 !important;
	text-align: center;
	float: left;
	margin-left: 9em;
}
.basic1 ul {
	margin: 0px;
	padding: 0px;
}
.basic1 ul li {
	list-style-type: none;
	color: #838383;
	margin: 15px 0 18px;
}
/*-- //pricing-table --*/

/*-- contact-us --*/
.contact-us {
	margin-top: 3%;
	margin-bottom: 5%;
}
.contact-us h3 {
	color: #333333;
	margin: 3em 0px 0.3em;
	text-align: center;
	font-size: 30px;
}
.contact-us h3 span {
	color: #ffcc33;
	margin-left: 0.3rem;
	font-weight: 600;
}
.contact-us p {
	color: #999999;
	margin: 0px 0px 1em;
	font-size: 18px;
	text-align: center;
}
.contact-us label {
	background: url(../images/what.png) no-repeat 100px 0px;
	display: block;
	width: 100%;
	height: 51px;
}
.dot {
	float: left;
	width: 25%;
	margin-left: 5em;
}
.dot label {
	background: url(../images/dot1.png) no-repeat 70px 0px;
	width: 212px;
	height: 125px;
	display: block;
}
.dot h5 {
	margin: 1em 0px;
	color: #8f8f8f;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
}
.dot h5 span {
	display: block;
	margin: 5px 0px;
}
.msg {
	float: left;
	width: 25%;
	margin-left: 7em;
}
.msg label {
	background: url(../images/message.png) no-repeat 60px 0px;
	width: 212px;
	height: 125px;
	display: block;
}
.msg h5 a {
	margin: 0px;
	color: #8f8f8f;
	font-weight: 600;
	font-size: 18px;
}
.msg h5 {
	margin: 1em 0px;
	color: #8f8f8f;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
}
.phone {
	float: left;
	width: 25%;
	margin-left: 7em;
}
.phone label {
	background: url(../images/call.png) no-repeat 68px 0px;
	width: 212px;
	height: 125px;
	display: block;
}
.phone h5 {
	margin: 1em 0px;
	color: #8f8f8f;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
}
.contact {
	margin: 8em 0px;
}
.contact input[type="text"] {
	outline: none;
	float: left;
	width: 30%;
	height: 4em;
	border: 1px solid #ccc;
	background: #fff;
	color: #999;
	padding-left: 15px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	font-weight: 600;
}
input[type="text"].in {
	margin: 0em 4em;
}
.contact input[type="submit"] {
	outline: none;
	padding: 15px 55px;
	background: #ffcc33;
	color: #fff;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	-ms-transition: 0.5s all;
	font-size: 16px;
	border: none;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
}
.contact input[type="submit"]:hover {
	background: #f3f3f3;
	color: #ffcc33;
}
.contact textarea {
	resize: none;
	outline: none;
	background: #fff;
	color: #999;
	height: 190px;
	width: 100%;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	-ms-border-radius: 5px;
	padding: 15px 0 0 15px;
	margin: 1em 0 4em 0em;
	font-weight: 600;
}
.map {
	margin-bottom: 5em;
	position: relative;
}
.map iframe {
	width: 100%;
	height: 500px;
}
.map span {
	position: absolute;
	top: 40%;
	left: 48%;
	background: url(../images/map-dot.png) no-repeat 0px 0px;
	width: 51px;
	height: 68px;
	display: block;
}
/*-- //contact-us --*/
/*-- footer --*/
.footer {
	padding: 2em 0px;
	background: #ffcc33;
}
.footer-left {
	float: left;
}
.footer-left p {
	color: #fff;
	// margin: 20px 0 0;
	font-size: 32px;
	// font-weight: 600;
}
.footer-left p a {
	color: #fff;
}
.footer-left p a:hover {
	text-decoration: none;
	color: #6f6f6f;
}
.footer-right {
	float: right;
}
.footer-right ul {
	display: flex;
	justify-content: center;
	padding: 0px;
	margin: 0px;
	margin-top: 1em;
}
.footer-right ul li {
	display: inline-block;
}
.footer-right ul li a.qq {
	@include back("qq", 25px, 25px);
	width: 35px;
	height: 35px;
	display: block;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
.footer-right ul li a.wechat {
	@include back("weixin", 25px, 25px);
	width: 35px;
	height: 35px;
	display: block;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
.footer-right ul li a.sina {
	@include back("weibo", 25px, 25px);
	width: 35px;
	height: 35px;
	display: block;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
.footer-right ul li a.qqweibo {
	@include back("weibo1", 25px, 25px);
	width: 35px;
	height: 35px;
	display: block;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
.footer-right ul li a.share {
	@include back("hover-2", 25px, 25px);
	width: 35px;
	height: 35px;
	display: block;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}
/*-- //footer --*/
/*-- to-top --*/
#toTop {
	display: none;
	text-decoration: none;
	position: fixed;
	bottom: 0px;
	right: 2%;
	overflow: hidden;
	z-index: 999;
	opacity: 1;
	width: 32px;
	height: 32px;
	border: none;
	text-indent: 100%;
	-webkit-animation: totop 1s ease infinite;
	animation: totop 1s ease infinite;
	background: url("../images/arrow.png") no-repeat 0px 0px;
}
#toTopHover {
	width: 32px;
	height: 32px;
	display: block;
	overflow: hidden;
	float: right;
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0);
}

/*-- //to-top --*/
@media (max-width: 1280px) {
	.internet-comm-left {
		margin-left: 6em;
	}
	.professional-left {
		padding-left: 4em;
		ul {
			top: 84.7%;
		}
		h3 {
			left: 14%;
		}
	}
}
@media (max-width: 1024px) {
	.professional-left {
		padding-left: 6em;
		ul {
			top: 79.3%;
			left: 32%;
		}
		h3 {
			left: 12%;
			font-size: 26px;
			margin: 2em 0px;
			label {
				font-size: 29px;
			}
		}
	}
	.contact input[type="text"] {
		width: 32%;
	}
	input[type="text"].in {
		margin: 0em 1.3em;
	}
	.top-nav ul {
		margin: 2.3em 0px 2em 11em;
	}
	.top-nav ul li {
		margin-left: 2em;
	}
	.banner-bottom h3 {
		background: url(../images/e.png) no-repeat 10em 0px;
		font-size: 37px;
	}
	.what-we-do label {
		background: url(../images/what.png) no-repeat 5px 0px;
	}
	.brand-identity-left {
		padding: 0 !important;
	}
	.brand-identity-left-fig1 {
		margin-right: 1em;
	}
	.brand-identity-left-fig {
		margin-left: 2em;
	}
	.brand-identity-left-text p {
		margin: 0 0 0 5em;
	}
	.want-to-know label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	.professional {
		padding-bottom: 1em;
	}
	.professional-right {
		padding-bottom: 3em;
	}
	.great-team label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	.maria h2 {
		margin: 10px 0px 10px 0px;
	}
	.maria p {
		margin: 10px 0em 10px 0px;
	}
	.maria ul {
		margin: 0px 0px 0px 4.5em;
	}
	.callbacks_tabs {
		left: 43%;
	}
	.welcome label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	.internet-comm-right p {
		margin: 0px 0 6em;
	}
	.internet-comm-left {
		width: 38%;
		margin-left: 0em;
	}
	.internet-comm-right {
		width: 62%;
		padding-left: 8em;
	}
	.internet-comm-right img {
		width: 91%;
	}
	.our-work label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	p.b-animate.b-from-left.b-delay03 {
		margin: 0px;
	}
	h5.b-animate.b-from-left.b-delay03 {
		font-size: 13px;
		margin: 0px;
	}
	.b-link-stripe .b-wrapper {
		padding: 18% 0px;
	}
	.internet-comm {
		padding: 10em 0px 7em;
	}
	.our-work {
		margin-top: 3%;
	}
	.pricing-table label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	.started a {
		padding: 17px 4em;
	}
	.grid-article-left {
		width: 49%;
		margin-left: 0%;
	}
	.january-right {
		margin-left: 0.5em;
		width: 57%;
	}
	.grid-article-right {
		width: 49%;
		margin-right: 0%;
	}
	.paragraph label {
		margin: 10px 0 10px 5em;
		padding: 5px 0 0px 47px;
	}
	.contact-us label {
		background: url(../images/what.png) no-repeat 0px 0px;
	}
	.dot {
		width: 27%;
		margin-left: 3em;
	}
	.msg {
		width: 27%;
		margin-left: 4em;
	}
	.phone {
		width: 27%;
		margin-left: 3em;
	}
	#filters {
		margin: 3% 0 7%;
	}
	.cap {
		top: 53%;
		padding: 2em 1em;
	}
}
@media (max-width: 768px) {
	.professional-left {
		padding-left: 0;
	}
	#portfoliolist .portfolio {
		width: 25%;
	}
	.top-nav {
		width: 5%;
		float: right;
	}
	span.menu {
		display: block;
		cursor: pointer;
		width: 100%;
		position: relative;
	}
	ul.nav1 {
		display: none;
		background: rgba(135, 133, 128, 0.96);
	}
	.top-nav ul li a {
		display: block;
		font-size: 18px;
	}
	.top-nav ul {
		position: absolute;
		padding: 0;
		margin: 0;
		top: 11%;
		left: 0%;
		width: 100%;
		z-index: 999;
	}
	.top-nav ul.nav1 li {
		display: inline-block;
		text-align: center;
		margin: 0;
		width: 100%;
		padding: 20px 0 15px;
	}
	span.menu {
		margin: 28px 0 20px;
	}
	.banner {
		min-height: 500px;
	}
	.banner-info {
		margin-top: 11em;
	}
	.banner-info p {
		margin: 1em 0px 5em;
	}
	.top-nav ul li a:hover {
		color: #ffca26;
		border-bottom: none;
		padding: 0px;
	}
	.banner-info h1 {
		font-size: 27px;
	}
	.banner-info p {
		font-size: 18px;
	}
	.banner-bottom {
		padding: 4.5em 0px;
	}
	.banner-bottom h3 {
		background: url(../images/e.png) no-repeat 7em 0px;
		margin: 0em 0px 0.5em;
	}
	.banner-bottom p {
		font-size: 18px;
	}
	.what-we-do h3 {
		font-size: 28px;
	}
	.what-we-do label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.brand-identity-left {
		float: left;
		width: 50%;
		padding: 0px;
	}
	.brand-identity-right {
		width: 50%;
		float: right;
		padding: 0 !important;
	}
	.brand-identity-left-text h2 {
		margin: 0px;
	}
	.brand-identity-left-text p {
		margin: 0px;
	}
	.brand-identity-left-text1 h2 {
		margin: 0px;
	}
	.brand-identity-left-text1 p {
		width: 100%;
	}
	.brand-identity-left-fig {
		margin-left: 1em;
	}
	.what-we-do-articles {
		width: 100%;
		.personaljj {
			width: 100%;
			padding: 0 1em 0 1em;
			line-height: 1.7em;
			margin: 0;
		}
	}
	.want-to-know {
		margin-bottom: 3em;
	}
	.want-to-know h3 {
		margin: 0em 0px 0.3em;
		font-size: 28px;
	}
	.want-to-know label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.callbacks_tabs {
		top: 113%;
	}
	.welcome {
		margin-top: 15%;
	}
	.our-work h3 {
		margin: 1em 0px 0.3em;
	}
	.professional-left {
		float: none;
		width: 100%;
		ul {
			top: 85%;
			left: 44%;
		}
		h3 {
			left: 46%;
			margin: 0px;
		}
	}
	.professional-right {
		float: none;
		width: 70%;
		margin-top: 8em;
		margin-left: 0px;
	}
	.professional-right h4 {
		margin: 0em 0px 1em;
		font-size: 23px;
	}
	.professional-right p {
		margin: 0px 0 10px;
	}
	.professional-grids-left1 {
		float: left;
		width: 10%;
		padding: 0px;
	}
	.professional-grids-finger {
		float: left;
		width: 20%;
		border-left: 1px solid #000;
	}
	.professional-grids-men {
		float: left;
		width: 20%;
	}
	.professional-grids-msg {
		float: left;
		width: 20%;
	}
	.professional-grids-msgtype {
		float: left;
		width: 20%;
		border-right: 1px solid #000;
	}
	.great-team label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.great-team h3 {
		margin: 2em 0px 0.3em;
		font-size: 28px;
	}
	.great-team {
		margin-top: 1em;
	}
	.grid-articles {
		padding: 4em 0px;
	}
	.contact-us h3 {
		margin: 0em 0px 0.3em;
	}
	.maria {
		float: left;
		width: 33%;
	}
	.maria img {
		width: 100%;
	}
	.maria p {
		overflow: hidden;
		height: 41px;
	}
	.research {
		margin-left: 2em;
		margin-top: 0px;
	}
	.cap {
		top: 50%;
		padding: 1em 1.5em;
	}
	.maria ul {
		margin: 0px 0 0em 2em;
	}
	.welcome h3 {
		font-size: 28px;
	}
	.welcome label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.internet-comm-left {
		width: 44%;
	}
	.figure {
		background: url(../images/sp.png) no-repeat -6px 0px;
	}
	.tv:hover div.figure {
		background: url(../images/sp.png) no-repeat -6px -46px;
	}
	.internet-comm-right {
		width: 53%;
		padding-left: 2em;
	}
	.internet-comm-right p {
		width: 100%;
	}
	.our-work label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.our-work h3 {
		font-size: 28px;
	}
	.b-link-stripe .b-wrapper {
		padding: 18% 0px;
	}
	.research h4 {
		font-size: 22px;
		margin: 0px;
	}
	.research p {
		overflow: hidden;
		height: 60px;
	}
	.fig-research {
		margin-bottom: 3em;
	}
	.pricing-table {
		margin-top: 4%;
	}
	.pricing-table label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.pricing-table h3 {
		font-size: 28px;
		margin: 1em 0px 0.3em;
	}
	.basic1 {
		margin-left: 7em;
	}
	.internet-comm {
		padding: 7em 0px;
	}
	.started a {
		padding: 17px 3em;
	}
	.january-left {
		margin-left: 0.5em;
		width: 35%;
	}
	.january-right {
		width: 60%;
	}
	.january-right h4 {
		overflow: hidden;
		height: 15px;
		margin: 0px 0px 5px;
	}
	.january-right p {
		overflow: hidden;
		height: 71px;
	}
	.paragraph p {
		margin: 15px 0px 10px 0.5em;
		font-size: 13px;
	}
	.paragraph label {
		margin: 10px 0 10px 1em;
		width: 70px;
	}
	.contact-us h3 {
		font-size: 28px;
	}
	.contact-us label {
		background: url(../images/what.png) no-repeat -181px 0px;
		width: 80%;
		margin: 0 auto;
	}
	.map iframe {
		height: 300px;
	}
	.dot {
		width: 30%;
		margin-left: 0;
	}
	.dot label {
		background: url(../images/dot1.png) no-repeat 42px 0px;
		width: 174px;
	}
	.dot h5 {
		font-size: 15px;
	}
	.msg {
		width: 26%;
		margin-left: 3em;
	}
	.msg h5 a {
		font-size: 15px;
	}
	.msg label {
		background: url(../images/message.png) no-repeat 39px 0px;
	}
	.phone {
		width: 30%;
		margin-left: 2em;
	}
	.phone h5 {
		font-size: 15px;
	}
	.phone label {
		background: url(../images/call.png) no-repeat 52px 0px;
		width: 203px;
	}
	input[type="text"].in {
		margin: 0em 1em;
	}
	.footer-left p {
		margin: 10px 0 0;
		font-size: 20px;
	}
	.contact input[type="submit"] {
		margin-left: 0.5em;
	}
}
@media (max-width: 640px) {
	.research p {
		overflow: hidden;
		height: 60px;
	}
	.research h4 {
		font-size: 20px;
	}
	#portfoliolist .portfolio {
		width: 33.3%;
	}
	.professional-left ul {
		top: 79.8%;
		left: 50%;
	}
	.professional-grids-left1 {
		width: 10.5%;
	}
	.banner-bottom h3 {
		background: url(../images/e.png) no-repeat 5.3em 0px;
	}
	.banner-bottom p {
		font-size: 17px;
	}
	.what-we-do h3 {
		font-size: 25px;
	}
	.what-we-do label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.what-we-do p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
		font-weight: 300;
	}
	.brand-identity-left-text h2 {
		font-size: 18px;
	}
	.brand-identity-left-text1 h2 {
		font-size: 18px;
	}
	.want-to-know h3 {
		font-size: 25px;
	}
	.want-to-know label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.want-to-know p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	.professional-left h3 {
		left: 37%;
	}
	.professional-grids-finger h3 {
		font-size: 22px;
	}
	.professional-grids-men h3 {
		font-size: 22px;
	}
	.professional-grids-msg h3 {
		font-size: 22px;
	}
	.professional-grids-msgtype h3 {
		font-size: 22px;
	}
	.great-team h3 {
		font-size: 25px;
	}
	.great-team label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.great-team p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	.cap {
		top: 42%;
	}
	.maria h2 {
		font-size: 25px;
	}
	.maria ul {
		margin: 0px 0 0em 0.5em;
	}
	.welcome label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.welcome p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	.welcome h3 {
		font-size: 25px;
	}
	.internet-comm-left {
		width: 50%;
	}
	.figure {
		background: url(../images/sp.png) no-repeat -10px 0px;
	}
	.text h5 {
		font-size: 13px;
	}
	.text p {
		font-size: 13px;
	}
	.figure4 {
		background: url(../images/sp.png) no-repeat -218px 0px;
	}
	.tv:hover div.figure4 {
		background: url(../images/sp.png) no-repeat -217px -46px;
	}
	.internet-comm-right {
		width: 50%;
	}
	.internet-comm-right h3 {
		font-size: 18px;
	}
	.internet-comm-right p {
		font-size: 14px;
	}
	.internet-comm-right img {
		width: 100%;
	}
	.our-work h3 {
		font-size: 25px;
	}
	.our-work label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.our-work p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	.fig {
		margin-left: 6em;
	}
	.pricing-table h3 {
		font-size: 25px;
	}
	.pricing-table label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.pricing-table p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	h5.b-animate.b-from-left.b-delay03 {
		font-size: 11px;
	}
	p.b-animate.b-from-left.b-delay03 {
		font-size: 11px;
	}
	.basic1 {
		margin-left: 2.5em;
		width: 29%;
	}
	.basic {
		width: 29%;
	}
	.value p span {
		font-size: 50px;
	}
	.january-right p {
		height: 52px;
	}
	.paragraph label {
		margin: 10px 0 10px 6.5em;
		width: 89px;
		padding: 5px 0 0px 62px;
	}
	.contact-us h3 {
		font-size: 25px;
	}
	.contact-us label {
		background: url(../images/what.png) no-repeat -230px 0px;
	}
	.contact-us p {
		font-size: 15px;
		overflow: hidden;
		height: 45px;
	}
	.paragraph p {
		margin: 15px 0px 10px 0.5em;
		font-size: 13px;
	}
	.dot h5 {
		font-size: 14px;
	}
	.msg h5 {
		font-size: 14px;
	}
	.phone h5 {
		font-size: 14px;
	}
	.contact {
		margin: 3em 0px;
	}
	.contact input[type="text"] {
		width: 100%;
	}
	input[type="text"].in {
		margin: 1em 0em;
	}
	.footer-left {
		width: 100%;
	}
	.footer-left p {
		text-align: center;
		margin: 0 auto;
	}
	.footer-right {
		float: left;
		width: 100%;
		margin-top: 1em;
	}
	.footer-right ul {
		margin: 0 auto;
		text-align: center;
	}
	@media (max-width: 480px) {
		.professional-left ul li a.facebook {
			width: 50px;
			height: 50px;
			background-size: 507%;
		}
		.professional-left ul li a.facebook:hover {
			background-size: 507%;
		}
		.professional-left ul li a.twitter {
			width: 50px;
			height: 50px;
			background-size: 507%;
		}
		.professional-left ul li a.twitter:hover {
			background-size: 507%;
		}
		.professional-left ul li a.in {
			width: 50px;
			height: 50px;
			background-size: 507%;
		}
		.professional-left ul li a.in:hover {
			background-size: 507%;
		}
		.research p {
			font-size: 13px;
			height: 36px;
		}
		.research h4 {
			font-size: 17px;
		}
		.maria ul li a.facebook1 {
			width: 35px;
			height: 40px;
			background-size: 507%;
		}
		.maria ul li a.facebook1:hover {
			background-size: 507%;
		}
		.maria ul li a.twitter1 {
			width: 35px;
			height: 40px;
			background-size: 507%;
		}
		.maria ul li a.twitter1:hover {
			background-size: 507%;
		}
		.maria ul li a.in1 {
			width: 35px;
			height: 40px;
			background-size: 507%;
		}
		.maria ul li a.in1:hover {
			background-size: 507%;
		}
		.banner {
			background-position: center center;
			min-height: 350px;
		}
		.banner-info h1 {
			font-size: 22px;
		}
		.banner-info p {
			font-size: 16px;
			margin: 1em 0px 3em;
		}
		.banner-info {
			margin-top: 6em;
		}
		.banner-info a {
			font-size: 21px;
		}
		.banner-bottom h3 {
			background: url(../images/e.png) no-repeat 3.2em 0px;
			margin: 0em;
		}
		.banner-bottom p {
			font-size: 16px;
			overflow: hidden;
			height: 30px;
		}
		.banner-bottom {
			padding: 2.5em 0px;
		}
		.what-we-do h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.what-we-do label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.brand-identity-left-text h2 {
			font-size: 15px;
		}
		.what-we-do p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}
		.brand-identity-left-text1 h2 {
			font-size: 15px;
		}
		.what-we-do-articles {
			margin: 1em 0px 1.5em;
		}
		.want-to-know h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.want-to-know label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.want-to-know p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}
		.top-nav ul.nav1 li {
			padding: 31px 0 15px;
			&:hover a {
				color: #ffcb31;
			}
		}
		.professional-left ul {
			top: 74%;
			left: 62%;
		}
		.professional-left h3 {
			left: 41%;
			font-size: 18px;
		}
		.professional-right h4 {
			margin: 0em 0px 0.5em;
		}
		.professional-right {
			margin-top: 5em;
			width: 85%;
		}
		.professional-right h4 {
			font-size: 21px;
		}
		.professional-left h3 label {
			font-size: 19px;
		}
		.great-team h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.great-team label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.professional-grids-finger h3 {
			font-size: 20px;
		}
		.professional-grids-men {
		}
		.professional-grids-men h3 {
			font-size: 20px;
		}
		.professional-grids-men p {
			font-size: 14px;
		}
		.professional-grids-msg {
		}
		.professional-grids-msg h3 {
			font-size: 20px;
		}
		.professional-grids-msg p {
			font-size: 14px;
		}
		.professional-grids-msgtype {
		}
		.professional-grids-msgtype h3 {
			font-size: 20px;
		}
		.professional-grids-msgtype p {
			font-size: 14px;
		}
		.professional-grids-finger p {
			font-size: 14px;
		}
		.cap {
			top: 32%;
			padding: 0.5em;
		}
		.maria h2 {
			font-size: 19px;
		}
		.great-team p {
			font-size: 13px;
			margin: 0;
			height: 21px;
		}
		.maria ul {
			margin: 0px;
			padding: 0px;
		}
		.maria ul li {
			margin: 0px 1px;
		}
		.callbacks_tabs {
			left: 34%;
		}
		.welcome h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.welcome label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.welcome p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}
		.internet-comm {
			padding: 3em 0px;
		}
		.callbacks_tabs {
			top: 108%;
		}
		.internet-comm-left {
			width: 90%;
			margin-left: 2em;
		}
		.figure {
			background: url(../images/sp.png) no-repeat -6px 0px;
		}
		.figure1 {
			background: url(../images/sp.png) no-repeat -56px 0px;
			width: 19%;
		}
		.figure2 {
			background: url(../images/sp.png) no-repeat -113px 0px;
		}
		.figure3 {
			width: 18%;
		}
		.internet-comm-right {
			width: 100%;
		}
		.internet-comm-right h3 {
			margin: 1em 0px 1em;
		}
		.our-work h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.our-work p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}

		.our-work label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		#filters li {
			margin: 0 1px;
		}
		.tv:hover div.figure1 {
			background: url(../images/sp.png) no-repeat -58px -46px;
		}
		.tv:hover div.figure2 {
			background: url(../images/sp.png) no-repeat -113px -46px;
		}
		.pricing-table h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.pricing-table label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.pricing-table p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}
		.value h4 {
			font-size: 19px;
		}
		.value p {
			font-size: 16px;
		}
		.value p span {
			font-size: 37px;
		}
		.basic1 {
			margin-left: 0em;
			width: 32.5%;
		}
		.basic {
			width: 32.5%;
		}
		.started a {
			padding: 17px 1em;
		}
		.grid-articles {
			padding: 4em 0px;
		}
		.grid-article-left {
			width: 100%;
			float: none;
		}
		.january-right h4 {
			overflow: inherit;
			height: inherit;
			margin: 10px 0px;
		}
		.january-right p {
			height: inherit;
			overflow: inherit;
		}
		.paragraph label {
			float: none;
			margin: 10px 0 10px 23.5em;
		}
		.paragraph p {
			margin: 8px 0px 10px 0.5em;
		}
		.grid-article-right {
			width: 100%;
			float: none;
		}
		.contact-us h3 {
			font-size: 22px;
			margin: 2em 0px 0.3em;
		}
		.contact-us label {
			background: url(../images/what.png) no-repeat -146px 0px;
			background-size: 188%;
		}
		.contact-us p {
			font-size: 13px;
			height: 21px;
			margin: 0;
		}
		.dot {
			width: 100%;
		}
		.dot label {
			background: url(../images/dot.png) no-repeat 144px 0px;
			width: 100%;
		}
		.msg {
			width: 100%;
			margin: 2em 0;
		}
		.msg label {
			background: url(../images/message.png) no-repeat 143px 0px;
			width: 100%;
		}
		.phone {
			width: 100%;
			margin: 0;
		}
		.phone label {
			background: url(../images/call.png) no-repeat 141px 0px;
			width: 100%;
		}
		.grid-article-left {
			margin: 1em 0;
		}
		.b-link-stripe .b-wrapper {
			padding: 14% 0px;
		}
		.fig-research {
			margin-bottom: 2em;
		}
	}
	@media (max-width: 320px) {
		.head-logo a img {
			width: 88%;
		}
		.professional-grids-left2 {
			border-left: 1px solid #555;
			padding: 14em 0px;
			border-right: 1px solid #555;
		}
		.banner-info a {
			font-size: 14px;
		}
		.paragraph {
			display: none;
		}
		.learn-more {
			margin-top: 2em;
		}
		.callbacks_tabs {
			left: 28%;
			top: 101%;
		}
		.maria ul li {
			margin: 0 3px;
		}
		.maria ul {
			margin: 0px 0 0em 3.5em;
		}
		#filters li {
			margin: 3px 1px;
		}
		.basic:nth-child(3) {
			display: none;
		}
		.basic1 {
			width: 50%;
		}
		.january-right {
			width: 55%;
		}
		.basic {
			width: 49%;
		}
		.professional-right p {
			font-size: 13px;
		}
		.professional-right {
			width: 100%;
		}
		.professional-right h4 {
			font-size: 19px;
		}
		.professional-left ul {
			top: 75.5%;
			left: 36%;
		}
		.professional-left {
			padding-left: 0;
		}
		.maria {
			margin-top: 0em;
		}
		.top-nav ul {
			top: 14%;
		}
		.top-nav ul li a {
			font-size: 15px;
		}
		.top-nav {
			width: 8%;
			float: right;
		}
		.banner-info {
			margin-top: 4em;
		}
		.banner-info h1 {
			font-size: 16px;
		}
		.banner-info p {
			font-size: 15px;
		}
		.banner {
			min-height: 265px;
		}
		.banner-bottom h3 {
			background: url(../images/e.png) no-repeat 1.2em 0px;
		}
		.what-we-do h3 {
			font-size: 20px;
		}
		.what-we-do p {
			margin: 0px;
		}
		.what-we-do label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.brand-identity-left-text h2 {
			font-size: 13px;
		}
		.brand-identity-left-text1 h2 {
			font-size: 13px;
			overflow: hidden;
			height: 15px;
		}
		.brand-identity-left-fig {
			margin-left: 0.5em;
		}
		.brand-identity-left-fig1 {
			margin-left: 0.5em;
		}
		.brand-identity-left-fig1 {
			margin-right: 0em;
		}
		.top-nav ul.nav1 li {
			padding: 10px 0;
		}
		.what-we-do-articles {
			margin: 1em 0px;
		}
		.want-to-know {
			margin-bottom: 0;
		}
		.want-to-know h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.want-to-know p {
			margin: 0px;
			overflow: hidden;
			height: 20px;
		}
		.want-to-know label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.professional-left h3 label {
			font-size: 17px;
		}
		.professional-left h3 {
			left: 22%;
			font-size: 16px;
		}
		.professional-right h4 {
			margin: 1em 0px 0.5em;
		}
		.professional-grids-finger {
			border-left: none;
			width: 50%;
			border-bottom: 1px solid #000;
			padding: 7em 0px;
		}
		.professional-grids-msgtype {
			width: 50%;
			border-right: none;
			padding: 7em 0px;
		}
		.professional-grids-msgtype {
		}
		.professional-grids-msgtype h3 {
			font-size: 24px;
		}
		.professional-grids-msgtype p {
			font-size: 15px;
			margin: 0.3em 0 0;
		}
		.professional-grids-men {
			width: 50%;
			border-right: none;
			border-bottom: 1px solid;
			padding: 7em 0px;
		}
		.professional-grids-men {
		}
		.professional-grids-men h3 {
			font-size: 24px;
		}
		.professional-grids-men p {
			font-size: 15px;
			margin: 0.3em 0 0;
		}
		.professional-grids-msg {
			width: 50%;
			border-right: 1px solid #000;
			padding: 7em 0px;
		}

		.professional-grids-msg h3 {
			font-size: 24px;
		}
		.professional-grids-msg p {
			font-size: 15px;
			margin: 0.3em 0 0;
		}
		.professional-grids-left1 {
			border: none;
			float: none;
		}
		.professional-grids-finger h3 {
			font-size: 24px;
		}
		.professional-grids-finger p {
			font-size: 15px;
			margin: 0.3em 0 0;
		}
		.great-team h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.great-team p {
			margin: 0px;
			overflow: hidden;
			height: 35px;
		}
		.great-team label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.fig img {
			width: 100%;
		}
		.cap {
			top: 41%;
			padding: 1em;
		}
		.col-md-4.maria:nth-child(2) {
			display: none;
		}
		.col-md-4.maria:nth-child(3) {
			display: none;
		}
		.maria {
			width: 100%;
		}
		.maria h2 {
			font-size: 11px;
			margin: 10px 0 0;
		}
		.welcome h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.welcome p {
			margin: 0px;
			overflow: hidden;
			height: 20px;
		}
		.welcome label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.internet-comm {
			padding: 5em 0px;
		}
		.internet-comm-left {
			width: 100%;
			margin-left: 0em;
		}
		.figure {
			background: url(../images/sp.png) no-repeat -12px 0px;
		}
		.tv:hover div.figure {
			background: url(../images/sp.png) no-repeat -12px -46px;
		}
		.figure1 {
			background: url(../images/sp.png) no-repeat -64px 0px;
		}
		.tv:hover div.figure1 {
			background: url(../images/sp.png) no-repeat -64px -46px;
		}
		.internet-comm-right {
			padding-left: 0em;
		}
		.our-work h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.our-work p {
			margin: 0px;
			overflow: hidden;
			height: 20px;
		}
		.our-work label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		#filters li span.active {
			font-size: 13px;
			padding: 5px;
		}
		#filters li span {
			padding: 5px;
			font-size: 13px;
		}
		.internet-comm-right h3 {
			font-size: 16px;
		}
		.internet-comm-right p {
			font-size: 13px;
			margin: 0 0 3em;
		}
		#portfoliolist .portfolio {
			width: 50%;
		}
		.fig {
			width: 17%;
		}
		.pricing-table h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.pricing-table p {
			margin: 0px;
			overflow: hidden;
			height: 20px;
		}
		.pricing-table label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.value h4 {
			font-size: 13px;
		}
		.value p {
			font-size: 12px;
		}
		.value p label {
			font-size: 18px;
		}
		.value p span {
			font-size: 24px;
		}
		.basic1 ul li {
			font-size: 12px;
		}
		.basic ul li {
			font-size: 12px;
		}
		.started a {
			padding: 10px;
			font-size: 8px;
		}
		.january-right h4 {
			overflow: hidden;
			height: 15px;
			margin: 0px 0px 5px;
		}
		.january-right p {
			height: 51px;
			overflow: hidden;
		}
		.paragraph p {
			margin: 0px 0px 0px 1em;
		}
		.contact-us h3 {
			font-size: 20px;
			margin: 1em 0px 0.3em;
		}
		.contact-us p {
			margin: 0px;
			overflow: hidden;
			height: 20px;
		}
		.contact-us label {
			background: url(../images/what.png) no-repeat -83px 0px;
			background-size: 180%;
			height: 20px;
		}
		.contact input[type="text"] {
			height: 3em;
		}
		.contact textarea {
			height: 125px;
			margin: 1em 0 1em 0em;
		}
		.contact input[type="submit"] {
			padding: 7px 37px;
			margin-left: 0;
		}
		.dot label {
			background: url(../images/dot.png) no-repeat 89px 0px;
		}
		.msg label {
			background: url(../images/message.png) no-repeat 88px 0px;
		}
		.phone label {
			background: url(../images/call.png) no-repeat 92px 0px;
		}
		.paragraph label {
			margin: 10px 0 10px 0.5em;
		}
	}
}

.professional-right {
	.skills {
		position: relative;
	}
	.skill1 {
		-webkit-transition: width 0.2s linear;
		transition: width 0.2s linear;
		&.animate {
			width: 100%;
		}
	}
	.space-block {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: #999;
	}
}
.fe-text {
	&:focus {
		color: #fff;
		text-decoration: none;
	}
}
.welcome-text {
	display: inline-block;
	border-right: 0.1em solid;
	width: 10em;
	white-space: nowrap;
	overflow: hidden;
	animation: typing 2s steps(10, end), cursor-blink 0.5s step-end infinite alternate;
}
@keyframes typing {
	from {
		width: 0;
	}
}

@keyframes cursor-blink {
	50% {
		border-color: transparent;
	}
}
.welcome-text-ani {
	mask: radial-gradient(circle at 50% 0, #000, transparent 20%, transparent 0);
	mask-size: 100% 100%;
	animation: scale2 5s infinite;
}
@keyframes scale2 {
	50%,
	100% {
		mask-size: 100% 3000%;
	}
}

.name {
	// 投影方向，投影距离，投影渐变
	-webkit-box-reflect: below 0px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.second-tit {
	-webkit-mask-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(red),
		color-stop(orange),
		color-stop(yellow),
		color-stop(green),
		color-stop(cyan),
		color-stop(blue),
		to(purple)
	);
	mask-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(red),
		color-stop(orange),
		color-stop(yellow),
		color-stop(green),
		color-stop(cyan),
		color-stop(blue),
		to(purple)
	);
	-webkit-mask-image: linear-gradient(to right, red, orange, yellow, green, cyan, blue, purple);
	mask-image: linear-gradient(to right, red, orange, yellow, green, cyan, blue, purple);
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ffcc33),
		color-stop(#333),
		color-stop(#333),
		color-stop(#333),
		color-stop(#ffcc33),
		color-stop(#333),
		color-stop(#333),
		color-stop(#333),
		color-stop(#ffcc33),
		color-stop(#333),
		color-stop(#ffcc33),
		to(#333)
	);
	background-image: linear-gradient(
		to right,
		#ffcc33,
		#333,
		#333,
		#333,
		#ffcc33,
		#333,
		#333,
		#333,
		#ffcc33,
		#333,
		#ffcc33,
		#333
	);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 200% 100%;
	-webkit-animation: bgp 5s linear infinite alternate;
	animation: bgp 5s linear infinite alternate;
}
@-webkit-keyframes bgp {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -100% 0;
	}
}
@keyframes bgp {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -100% 0;
	}
}

.scatterbtn {
	color: #fff;
	cursor: pointer;
}
